import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { graphql, Link, navigate } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/Layout/Layout.component";
import {
  WTB_HEADER_TEXT_1,
  WTB_HEADER_TEXT_2,
  WTB_PARA_TEXT,
  WTB_BUTTON_TEXT,
  WTF_PARA_TEXT,
  WTF_PARA_LINK_TXT,
  WTF_BUTTON_TEXT,
  PRODUCT_HEADER_TEXT,
  PRODUCT_BUTTON_TEXT,
  RECIPES_HEADER_TEXT_1,
  RECIPES_HEADER_TEXT_SUP,
  RECIPES_HEADER_TEXT_2,
  RECIPES_PARA_TEXT,
  RECIPES_BUTTON_TEXT,
  STORY_HEADER_TEXT,
  STORY_PARA_TEXT,
  STORY_BUTTON_TEXT,
} from "../constants/pages/Home.constant";

class IndexPage extends Component {
  constructor(props) {
    super(props);
    this.state = { screenWidth: 0, screenHeight: 0 };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
    });
  };

  pageNavigate = (pagePath) => {
    navigate(pagePath);
  };

  render() {
    const { data } = this.props;
    const { screenWidth, screenHeight } = this.state;

    return (
      <Layout
        metaTitle = "Galileo® Salame | Authentic Italian Salame"
        metaDescription = "Galileo® Salame has been crafting traditional Italian salame in the San Francisco Bay Area for more than 100 years. Learn more about our products today."
      >
        <Container className="gagl-home-wrapper">
          <Row className="gagl-home-container gagl-home-wtb-container m-0">
            <h1 className="gagl-home-header-text gagl-home-header-mobtext">
              {WTB_HEADER_TEXT_1}
              <br />
              {WTB_HEADER_TEXT_2}
            </h1>
            <Col md="6" xs="12" className="p-0">
              <Img
                className={"gagl-home-banner-img"}
                alt={"italian sandwich"}
                fluid={
                  screenWidth > 767
                    ? data.wheretobuyBanner_img.childImageSharp.fluid
                    : data.wheretobuyBanner_img_mobile.childImageSharp.fluid
                }
              />
            </Col>
            <Col md="6" xs="12" className="gagl-home-wtb-content">
              <h1 className="gagl-home-header-text gagl-home-header-desktext">
                {WTB_HEADER_TEXT_1}
                <br />
                {WTB_HEADER_TEXT_2}
              </h1>
              <p className="gagl-home-para-text">{WTB_PARA_TEXT}</p>
              <button
                className="gagl-home-btn"
              >
                <Link to="/where-to-buy/" title={WTB_BUTTON_TEXT}>{WTB_BUTTON_TEXT}</Link>
              </button>
            </Col>
          </Row>
          <Row
            className="gagl-home-container gagl-home-product-container m-0 "
            role="img"
            aria-label="hanging salame"
          >
            <Col xs="12" className="gagl-home-product-content">
              <h2 className="gagl-home-header-text">{PRODUCT_HEADER_TEXT}</h2>
              <Row className="gagl-home-product-img-wrapper m-0">
                <Col xs="4" className="p-0">
                  <Link to="/our-products/sliced-italian-dry-salame/">
                    <Img className={"gagl-home-banner-img gagl-home-banner-img-first gagl-cursor-pointer"}
                      alt={"galileo salame"} fluid={data.product1_img.childImageSharp.fluid} />
                  </Link>
                </Col>
                <Col xs="4" className="p-0">
                  <Link to="/our-products/sliced-all-natural-italian-dry-salame/">
                    <Img className={"gagl-home-banner-img gagl-cursor-pointer"} alt={"galileo uncured salame"}
                      fluid={data.product2_img.childImageSharp.fluid} />
                  </Link>
                </Col>
                <Col xs="4" className="p-0">
                  <Link to="/our-products/sliced-pepperoni/">
                    <Img className={"gagl-home-banner-img gagl-home-banner-img-last gagl-cursor-pointer"}
                      alt={"galileo pepperoni"} fluid={data.product3_img.childImageSharp.fluid} />
                  </Link>
                </Col>
              </Row>
              <button className="gagl-home-btn">
                <Link to="/our-products/" title={PRODUCT_BUTTON_TEXT}>{PRODUCT_BUTTON_TEXT}</Link>
              </button>
            </Col>
          </Row>
          <Row className="gagl-home-container gagl-home-recipes-container m-0">
            <Col xs="12" md="6" className="gagl-home-recipes-content">
              <h1 className="gagl-home-header-text">
                {RECIPES_HEADER_TEXT_1}
                <sup className="gagl-home-sup-text">{RECIPES_HEADER_TEXT_SUP}</sup>
                {RECIPES_HEADER_TEXT_2}
              </h1>
              <p className="gagl-home-para-text gagl-home-para-desktext">
                {RECIPES_PARA_TEXT}
              </p>
              <button
                className="gagl-home-btn gagl-home-deskbtn"
              >
                <Link to="/recipes/" title={RECIPES_BUTTON_TEXT}>{RECIPES_BUTTON_TEXT}</Link>
              </button>
            </Col>
            <Col xs="12" md="6" className="p-0">
              <Img
                className={"gagl-home-banner-img"}
                alt={"pepperoni cheese bread"}
                fluid={data.recipebanner_img.childImageSharp.fluid}
              />
            </Col>
            <Col
              xs="12"
              className="gagl-home-recipes-content gagl-home-recipes-mobcontent"
            >
              <p className="gagl-home-para-text">{RECIPES_PARA_TEXT}</p>
              <button
                className="gagl-home-btn"
              >
                <Link to="/recipes/" title={RECIPES_BUTTON_TEXT}>{RECIPES_BUTTON_TEXT}</Link>
              </button>
            </Col>
          </Row>
          <Row
            className="gagl-home-container gagl-home-story-container m-0"
            role="img"
            aria-label="galileo storefront"
          >
            <Col xs="12" className="gagl-home-story-content">
              <h2 className="gagl-home-header-text">{STORY_HEADER_TEXT}</h2>
              <p className="gagl-home-para-text">{STORY_PARA_TEXT}</p>
              <button
                className="gagl-home-btn"
              >
                <Link to="/our-story/" title={STORY_BUTTON_TEXT}>{STORY_BUTTON_TEXT}</Link>
              </button>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default IndexPage;

export const pageQuery = graphql`
  query homePageQuery {
    wheretobuyBanner_img: file(
      relativePath: { eq: "recipes/Italian-classico-sandwich.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 515, maxWidth: 960, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    wheretobuyBanner_img_mobile: file(
      relativePath: { eq: "home/italian-sandwich-mob.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 232, maxWidth: 375, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
    welcometofamilyBanner_img: file(
      relativePath: { eq: "home/galileo_italy_trip.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 515, maxWidth: 960, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    product1_img: file(relativePath: { eq: "home/galileo-salame.png" }) {
      childImageSharp {
        fluid(maxHeight: 261, maxWidth: 247, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    product2_img: file(
      relativePath: { eq: "home/galileo-uncured-salame.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 261, maxWidth: 247, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    product3_img: file(relativePath: { eq: "home/galileo-pepperoni.png" }) {
      childImageSharp {
        fluid(maxHeight: 261, maxWidth: 247, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    recipebanner_img: file(
      relativePath: { eq: "recipes/Pepperoni-cheese-bread.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 576, maxWidth: 960, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
